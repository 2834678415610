#contact {
  padding-top: 5rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}
.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 75%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 1em 2rem 1rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--dark-gray-color);

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin-right: 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px var(--hover-color);
    }

    @media screen and (max-width: 450px) {
      width: 100%;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--dark-gray-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--dark-gray-color);

      font-family: var(--font-base);
      color: var(--white-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--hover-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);
    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 0.3rem 2rem 0 0;
    margin-left: 2rem;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
  @media screen and (max-width: 450px) {
    width: 86%;
  }
}
